import React from 'react';

import { Loading } from 'components/shared/Loading';

import { GeneralSection } from './components/GeneralSection';
import { LayersSection } from './components/LayersSection';
import { MaterialsSection } from './components/MaterialsSection';
// import { TheorySection } from './components/TheorySection';
import { HintsSection } from './components/HintsSection';
import { StylesSection } from './components/StylesSection';
import { FilesSection } from './components/FilesSection';

import styles from './MainSettings.styles.scss';

const MainSettings = ({
  loading,
  figmaFileImages,
  currentTask,
  designType,
}) => {
  const namePrefix =
    currentTask === 'INITIAL' ? 'initialState' : `tasks[${currentTask}]`;
  const layersNamePrefix =
    currentTask === 'INITIAL'
      ? 'initialState.layers'
      : `tasks[${currentTask}].layers`;
  const isInitialState = currentTask === 'INITIAL';

  return (
    <div className={styles.settings}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <GeneralSection
            namePrefix={namePrefix}
            taskIndex={currentTask}
            designType={designType}
          />
          <LayersSection
            figmaFileImages={figmaFileImages}
            isInitialState={isInitialState}
            taskIndex={currentTask}
            namePrefix={layersNamePrefix}
          />
          {!isInitialState && (
            <>
              {/* <TheorySection namePrefix={namePrefix} /> */}
              <StylesSection namePrefix={namePrefix} />
              <HintsSection namePrefix={namePrefix} />
              <MaterialsSection namePrefix={namePrefix} />
              <FilesSection namePrefix={namePrefix} />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(MainSettings);
