import React from 'react';

import { getTranslation } from 'helpers/getTranslation';

import { Typography } from 'components/shared/Typography';
import { Button } from 'components/shared/Button';

import { FormikField } from 'components/FormikField';
import { SwitchField, InputField } from 'components/FormikFields';

import styles from './FilesSection.styles.scss';

const FilesSection = ({
  withFiles,
  readOnly,
  files,
  namePrefix,
  onAddFileClick,
}) => {
  return (
    <div className={styles.section}>
      <div className={styles.header}>
        <FormikField
          withControl={false}
          name={`${namePrefix}.withFiles`}
          component={SwitchField}
          componentProps={{ readOnly, type: 'checkbox' }}
        />
        <Typography
          className={styles.headerText}
          variant="body1"
          weight="medium"
          mode="compact"
        >
          {getTranslation(
            'figma_lesson_page.main_settings.files_section_title',
          )}
        </Typography>
      </div>
      {withFiles && (
        <>
          <Typography className={styles.text} variant="body1" mode="compact">
            {getTranslation(
              'figma_lesson_page.main_settings.files_section.select_image',
            )}
          </Typography>
          <div className={styles.files}>
            {files.map((_, fileIndex) => (
              <div className={styles.file} key={fileIndex}>
                <FormikField
                  name={`${namePrefix}.files.${fileIndex}.name`}
                  component={InputField}
                  controlProps={{
                    required: true,
                    label: 'File name',
                  }}
                />
                <FormikField
                  name={`${namePrefix}.files.${fileIndex}.url`}
                  component={InputField}
                  controlProps={{
                    required: true,
                    label: 'File url',
                  }}
                />
              </div>
            ))}
          </div>
          <Button variant="primary" onClick={onAddFileClick}>
            Add file
          </Button>
        </>
      )}
    </div>
  );
};

export default React.memo(FilesSection);
