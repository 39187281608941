import { v4 as uuid } from 'uuid';

import { transformLayers } from './transformLayers';

export const getPrototypeTasks = ({ filePage, currentTasks }) => {
  if (!currentTasks || currentTasks.length === 0) {
    return [
      {
        id: uuid(),
        name: 'Task 1',
        preview: null,
        layers: transformLayers({
          layers: filePage.children,
          currentLayers: [],
          parent: null,
        }),
        text: '',
        withTheory: false,
        withHints: false,
        withMaterials: false,
        withFiles: false,
        checkStyles: false,
        theoryBlocks: [],
        hintsBlocks: [],
        materials: [],
        files: [],
      },
    ];
  }

  const tasks = currentTasks.map((currentTask) => {
    const layers = transformLayers({
      layers: filePage.children,
      currentLayers: [],

      parent: null,
    });

    return {
      id: uuid(),
      name: currentTask.name,
      preview: currentTask.preview,
      layers,
      withTheory: currentTask.withTheory,
      withHints: currentTask.withHints,
      withMaterials: currentTask.withMaterials,
      withFiles: currentTask.withFiles,
      checkStyles: currentTask.checkStyles,
      theoryBlocks: currentTask.theoryBlocks,
      hintsBlocks: currentTask.hintsBlocks,
      materials: currentTask.materials,
      files: currentTask.files,
      text: currentTask.text,
    };
  });

  return tasks;
};
