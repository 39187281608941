import React from 'react';
import { getIn, useFormikContext } from 'formik';

import FilesSection from './FilesSection';

const FilesSectionContainer = ({ namePrefix, ...restProps }) => {
  const { values, setFieldValue } = useFormikContext();

  const withFiles = getIn(values, `${namePrefix}.withFiles`);
  const files = getIn(values, `${namePrefix}.files`);

  const handleAddFileClick = () => {
    setFieldValue(`${namePrefix}.files`, [
      ...files,
      {
        name: '',
        url: '',
      },
    ]);
  };

  return (
    <FilesSection
      {...restProps}
      withFiles={withFiles}
      readOnly={values.readOnly}
      files={files}
      namePrefix={namePrefix}
      onAddFileClick={handleAddFileClick}
    />
  );
};

export default React.memo(FilesSectionContainer);
